import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { CountriesEnums } from '../../common/constants/Enums';
import { CatalogRequestDynamicControls, ICatalogRequestDynamicControlsParameters } from '../form-controls/catalog-request.form-controls';
import { IMetaItem } from '../interfaces/base';
import { ICatalogRequest } from '../interfaces/catalog-request';

export interface ICatalogRequestDynamicControlsParametersPartial extends ICatalogRequestDynamicControlsParameters {
    countries: IMetaItem[];
    states: IMetaItem[];
}
export class CatalogRequestDynamicControlsPartial extends CatalogRequestDynamicControls {
    constructor(request?: ICatalogRequest, additionalParameters?: ICatalogRequestDynamicControlsParametersPartial) {
        super(request, additionalParameters);

        this.Form.Quantity.value = 1;

        (<DynamicField>this.Form.EmailAddress).validation = [
            Validators.required,
            Validators.maxLength(50),
            Validators.email,
            Validators.pattern(/^.+@.+\..+$/),
        ];
        (<DynamicField>this.Form.EmailAddress).validators = { required: true, showRequired: true, maxlength: 50, email: true };

        (<DynamicField>this.Form.Quantity).validation = [Validators.min(1)];
        (<DynamicField>this.Form.Quantity).validators = { min: 1 };

        // US states dropdown -- Additional field for when Country is US
        this._setStateDropdown(request, additionalParameters);

        // Zip code info
        this._setZipCode(request);

        // Database stores country as a varchar instead of foreign key
        this.Form.Country.options = additionalParameters.countries;
        (this.Form.Country.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: null,
            scale: null,
        })),
            (this.Form.Country.value = additionalParameters.countries.find((c) => c.Id === CountriesEnums.US).Id);

        (this.Form.FacilityName.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
            scale: null,
        })),
            this._setRequireds();
        this._setLabels();
    }

    private _setStateDropdown(request: ICatalogRequest, additionalParameters?: ICatalogRequestDynamicControlsParametersPartial): void {
        const state = additionalParameters.states.find((s) => s.Name === request?.Address4);
        const stateId = state ? state.Id : null;
        this.Form.StateId = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'StateId',
            options: additionalParameters.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: stateId,
        });
    }

    private _setZipCode(request: ICatalogRequest): void {
        this.Form.USZipCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'Postal/Zip Code',
            name: 'USZipCode',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(10), Validators.minLength(5), Validators.required],
            validators: { maxlength: 10, minlength: 5, required: true, showRequired: true },
            value: request?.Address5,
        });
    }

    private _setRequireds(): void {
        this.Form.Quantity.setRequired(true);
        this.Form.FirstName.setRequired(true);
        this.Form.LastName.setRequired(true);
        this.Form.Country.setRequired(true);
        this.Form.Address1.setRequired(true);
        this.Form.City.setRequired(true);
        this.Form.StateId.setRequired(true);
        this.Form.Address4.setRequired(true);
        this.Form.Address5.setRequired(true);
        this.Form.Country.setRequired(true);
    }

    private _setLabels(): void {
        this.Form.Comments.labelHtml =
            '<label>First, if you would be so kind, please tell us how you found us (which search engine, which keywords, a friend, etc.). Please also enter comments here (what you are interested in: CDA, state training, etc.)</label>';
        this.Form.Quantity.labelHtml = '<label>Number of Catalogs Requested</label>';
        this.Form.FacilityName.labelHtml = '<label>Facility Name (If you have one)</label>';
        this.Form.Address1.labelHtml = '<label>Address Line 1</label>';
        this.Form.Address2.labelHtml = '<label>Address Line 2</label>';
        this.Form.Address3.labelHtml = '<label>Address Line 3</label>';
        this.Form.Address4.labelHtml = '<label>State/Province</label>';
        this.Form.Address5.labelHtml = '<label>Postal/Zip Code</label>';
    }
}
